import React from 'react'
import './clients.css'
import Marquee from 'react-fast-marquee'
import {
  Client1,
  Client2,
  Client3,
  Client4,
  Client5,
  Client6,
  Client7,
} from '../../Assets/Images/Clients'

const Clients = () => {
  const clients = [
    Client1,
    Client2,
    Client3,
    Client4,
    Client5,
    Client6,
    Client7,
  ]

  return (
    <div className="clients_block">
      <div className="customContainer clients_img_block">
        <Marquee velocity={50} pauseOnHover={true} resetAfterTries={200}>
          {clients.map((client, index) => (
            <img
              key={index}
              className="client_img"
              src={client}
              alt={`Client ${index + 1}`}
            />
          ))}
        </Marquee>
      </div>
    </div>
  )
}

export default Clients
