import React, { useState } from 'react'
import styles from './MainBlock.module.css'
import PopUpMain from '../../Components/PopUpMain/PopUpMain'

const MainBlock = () => {
  const [showPopup, setShowPopup] = useState(false)

  const handleShow = () => setShowPopup(true)
  const handleClose = () => setShowPopup(false)
  return (
    <div className={styles.mainBlock} id={'start'}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          <span className={styles.title_mod}>Меблі</span> на замовлення
        </h1>
        <p className={styles.subtitle}>Для вас ми здійснюємо мрії!</p>
        <button onClick={handleShow} className={styles.button}>
          Отримати консультацію
        </button>
      </div>
      <PopUpMain show={showPopup} handleClose={handleClose} title="Popup Title">
        <p>This is the content of the popup.</p>
      </PopUpMain>
    </div>
  )
}

export default MainBlock
