import React, { useState, useEffect, useRef } from 'react'
import { useSwipeable } from 'react-swipeable'
import style from './GalleryVideo.module.css'
import {
  gallery_arrow_left,
  gallery_arrow_right,
} from '../../Assets/Images/Carousel'
import { useWindowSize } from '../../Components/Hooks'

// Масив слайдів з посиланнями на відео
const slides = [
  { videoUrl: 'https://www.youtube.com/embed/h7cy6-DIvVM' },
  { videoUrl: 'https://www.youtube.com/embed/JXjO0-ZweOM' },
  { videoUrl: 'https://www.youtube.com/embed/KoRZLI1QHM0' },
  { videoUrl: 'https://www.youtube.com/embed/dCNZBQ4ytR8' },
  { videoUrl: 'https://www.youtube.com/embed/a9tHMSiPWsU' },
  { videoUrl: 'https://www.youtube.com/embed/KX7NPZMgplw' },
]

const GalleryVideo = () => {
  const { width } = useWindowSize()
  const isMobile = width <= 720
  const slidesToShow = isMobile ? 1 : 3
  const [currentIndex, setCurrentIndex] = useState(slidesToShow)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)
  // const [fullscreenVideoUrl, setFullscreenVideoUrl] = useState(null) // Закоментована змінна
  const transitionRef = useRef()

  const totalSlides = [
    ...slides.slice(-slidesToShow),
    ...slides,
    ...slides.slice(0, slidesToShow),
  ]

  const handleNext = () => {
    if (!isTransitioning) {
      setCurrentIndex((prevIndex) => prevIndex + 1)
      setIsTransitioning(true)
    }
  }

  const handlePrev = () => {
    if (!isTransitioning) {
      setCurrentIndex((prevIndex) => prevIndex - 1)
      setIsTransitioning(true)
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  useEffect(() => {
    if (isTransitioning) {
      transitionRef.current = setTimeout(() => {
        setIsTransitioning(false)
        if (currentIndex === slidesToShow - 1) {
          setCurrentIndex(slides.length + slidesToShow)
        } else if (currentIndex === slides.length + slidesToShow) {
          setCurrentIndex(slidesToShow)
        }
      }, 500)
    }
    return () => clearTimeout(transitionRef.current)
  }, [currentIndex, isTransitioning, slidesToShow])

  const toggleFullscreen = (videoUrl = null) => {
    // setFullscreenVideoUrl(videoUrl) // Закоментоване використання
    setIsFullscreen((prev) => !prev)
  }

  return (
    <div className={style.gallery_video_block}>
      <div className={style.gallery}>
        <div>
          <div className={style.carouselWrap}>
            <button
              className={`${style.carouselArrow} ${style.left}`}
              onClick={handlePrev}
              disabled={isTransitioning}
            >
              <img src={gallery_arrow_left} alt="" />
            </button>
            <button
              className={`${style.carouselArrow} ${style.right}`}
              onClick={handleNext}
              disabled={isTransitioning}
            >
              <img src={gallery_arrow_right} alt="" />
            </button>
            <div className={style.carousel} {...handlers}>
              <div
                className={style.carouselContainer}
                style={{
                  transform: `translateX(-${
                    (currentIndex - slidesToShow) * (100 / slidesToShow)
                  }%)`,
                  transition: isTransitioning
                    ? 'transform 0.5s ease-in-out'
                    : 'none',
                }}
              >
                {totalSlides.map((slide, index) => (
                  <div
                    key={index}
                    className={style.carouselSlide}
                    onClick={() =>
                      !isFullscreen && toggleFullscreen(slide.videoUrl)
                    }
                    style={{ pointerEvents: isFullscreen ? 'none' : 'auto' }}
                  >
                    <iframe
                      src={slide.videoUrl}
                      width="100%"
                      height="100%"
                      title="Video Slide"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                ))}
              </div>

              <div className={style.carouselDots}>
                {slides.map((_, index) => (
                  <span
                    key={index}
                    className={`${style.carouselDot} ${
                      index + slidesToShow === currentIndex
                        ? style.activeDot
                        : ''
                    }`}
                    onClick={() => {
                      if (!isTransitioning)
                        setCurrentIndex(index + slidesToShow)
                    }}
                  ></span>
                ))}
              </div>
            </div>
          </div>

          {/*{isFullscreen && (*/}
          {/*  <div*/}
          {/*    className={style.fullscreenOverlay}*/}
          {/*    onClick={() => toggleFullscreen()}*/}
          {/*  >*/}
          {/*    <iframe*/}
          {/*      src={fullscreenVideoUrl}*/}
          {/*      width="100%"*/}
          {/*      height="100%"*/}
          {/*      title="Fullscreen Video"*/}
          {/*      frameBorder="0"*/}
          {/*      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
          {/*      allowFullScreen*/}
          {/*      className={style.fullscreenVideo}*/}
          {/*    ></iframe>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </div>
    </div>
  )
}

export { GalleryVideo }
