import './App.css'
import { ToastContainer } from 'react-toastify'
import Header from './Modules/Header/Header'
import Footer from './Modules/Footer/Footer'
import FooterCopyright from './Modules/Footer/FooterCopyright'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MainPage from './Pages/MainPage/MainPage'
import ThankPage from './Pages/ThankPage/ThankPage'

function App() {
  return (
    <div className="App">
      <Router>
        <ToastContainer />
        <Header />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/thankyou" element={<ThankPage />} />
        </Routes>
        <Footer />
        <FooterCopyright />
      </Router>
    </div>
  )
}

export default App
