import React, { useEffect, useState } from 'react'
import style from './Header.module.css'
import { HeaderLogo } from '../../Assets/Images/Header'
import { Link } from 'react-scroll'

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen)
  }
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={`${style.wrap} ${scrolled ? style.scrolled : ''}`}>
      <header className={`${'customContainer'} ${style.header}`}>
        <Link to={'start'} className={style.logoContainer}>
          <img src={HeaderLogo} alt="" className={style.logoContainer} />
        </Link>

        {/* Desktop Navigation */}
        <nav className={style.navWeb}>
          <ul>
            <Link to={'carousel'}>
              <li>
                <a href="/">Послуги</a>
              </li>
            </Link>
            <Link to={'cases'}>
              <li>
                <a href="/">Реалізовані проекти</a>
              </li>
            </Link>
            <Link to={'workflow'}>
              <li>
                <a href="/">Співпраця</a>
              </li>
            </Link>
          </ul>
        </nav>
        <a href={'tel:+3806365497715'} className={style.phoneInput}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M3.62 7.79C5.06 10.62 7.38 12.93 10.21 14.38L12.41 12.18C12.68 11.91 13.08 11.82 13.43 11.94C14.55 12.31 15.76 12.51 17 12.51C17.55 12.51 18 12.96 18 13.51V17C18 17.55 17.55 18 17 18C7.61 18 0 10.39 0 1C0 0.45 0.45 0 1 0H4.5C5.05 0 5.5 0.45 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z"
              fill="white"
            />
          </svg>
          +380-(63)-549-77-15
        </a>
        {/* Burger Menu for Mobile */}
        <div className={style.burgerMenu} onClick={toggleMenu}>
          <div
            className={`${style.burger} ${isMenuOpen ? style.burgerOpen : ''}`}
          ></div>
        </div>
      </header>
      <nav className={`${style.navMobile} ${isMenuOpen ? style.open : ''}`}>
        <ul>
          <li>
            <Link to={'carousel'}>
              <a href="/">Послуги</a>
            </Link>
          </li>
          <li>
            <Link to={'cases'}>
              <a href="/">Реалізовані проекти</a>
            </Link>
          </li>
          <li>
            <Link to={'workflow'}>
              <a href="/">Співпраця</a>
            </Link>
          </li>
        </ul>

        <div className={style.phoneMb}>
          <a href={'tel:+380-(63)-549-77-15 '} className={style.phoneInputMb}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M3.62 7.79C5.06 10.62 7.38 12.93 10.21 14.38L12.41 12.18C12.68 11.91 13.08 11.82 13.43 11.94C14.55 12.31 15.76 12.51 17 12.51C17.55 12.51 18 12.96 18 13.51V17C18 17.55 17.55 18 17 18C7.61 18 0 10.39 0 1C0 0.45 0.45 0 1 0H4.5C5.05 0 5.5 0.45 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z"
                fill="white"
              />
            </svg>
            +380-(63)-549-77-15
          </a>
          <a href={'tel:+380-(96)-071-83-71 '} className={style.phoneInputMb}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M3.62 7.79C5.06 10.62 7.38 12.93 10.21 14.38L12.41 12.18C12.68 11.91 13.08 11.82 13.43 11.94C14.55 12.31 15.76 12.51 17 12.51C17.55 12.51 18 12.96 18 13.51V17C18 17.55 17.55 18 17 18C7.61 18 0 10.39 0 1C0 0.45 0.45 0 1 0H4.5C5.05 0 5.5 0.45 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z"
                fill="white"
              />
            </svg>
            +380-(96)-071-83-71
          </a>
          <a href={'+380-(99)-783-15-71 '} className={style.phoneInputMb}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M3.62 7.79C5.06 10.62 7.38 12.93 10.21 14.38L12.41 12.18C12.68 11.91 13.08 11.82 13.43 11.94C14.55 12.31 15.76 12.51 17 12.51C17.55 12.51 18 12.96 18 13.51V17C18 17.55 17.55 18 17 18C7.61 18 0 10.39 0 1C0 0.45 0.45 0 1 0H4.5C5.05 0 5.5 0.45 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z"
                fill="white"
              />
            </svg>
            +380-(99)-783-15-71
          </a>
        </div>
      </nav>
      {isMenuOpen && <div className={style.overlay} onClick={toggleMenu}></div>}
    </div>
  )
}

export default Header
