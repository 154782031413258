// import React, { useState, useEffect, useRef } from 'react'
// import { useSwipeable } from 'react-swipeable'
// import { useWindowSize } from '../../Components/Hooks/useWindowSize'
// import style from '../Carousel/Carousel.module.css'
// import {
//   first_Slide1,
//   first_Slide2_1,
//   first_Slide2_2,
//   first_Slide3,
//   second_Slide1,
//   second_Slide2_1,
//   second_Slide2_2,
//   second_Slide3,
//   third_Slide1,
//   third_Slide2_1,
//   third_Slide2_2,
//   third_Slide3,
//   gallery_arrow_left,
//   gallery_arrow_right,
// } from '../../Assets/Images/CarouselDoors'
// import PopUpDoor from '../../Components/PopUpDoor/PopUpDoor'
//
// const largeScreenSlides = [
//   {
//     slide1: first_Slide1,
//     slide2_1: first_Slide2_1,
//     slide2_2: first_Slide2_2,
//     slide3: first_Slide3,
//   },
//   {
//     slide1: second_Slide1,
//     slide2_1: second_Slide2_1,
//     slide2_2: second_Slide2_2,
//     slide3: second_Slide3,
//   },
//   {
//     slide1: third_Slide1,
//     slide2_1: third_Slide2_1,
//     slide2_2: third_Slide2_2,
//     slide3: third_Slide3,
//   },
// ]
//
// const smallScreenSlides = [
//   { slide1: first_Slide1 },
//   { slide1: first_Slide2_1 },
//   { slide1: first_Slide2_2 },
//   { slide1: first_Slide3 },
//   { slide1: second_Slide1 },
//   { slide1: second_Slide2_1 },
//   { slide1: second_Slide2_2 },
//   { slide1: second_Slide3 },
//   { slide1: third_Slide1 },
//   { slide1: third_Slide2_1 },
//   { slide1: third_Slide2_2 },
//   { slide1: third_Slide3 },
// ]
//
// const CarouselDoors = () => {
//   const { width } = useWindowSize()
//   const isLargeScreen = width >= 768
//   const slides = isLargeScreen ? largeScreenSlides : smallScreenSlides
//   const totalSlides = [slides[slides.length - 1], ...slides, slides[0]]
//
//   const [currentIndex, setCurrentIndex] = useState(1)
//   const [isTransitioning, setIsTransitioning] = useState(false)
//   const [isFullscreen, setIsFullscreen] = useState(false)
//   const [fullscreenImg, setFullscreenImg] = useState(null)
//   const [showPopup, setShowPopup] = useState(false)
//
//   const transitionRef = useRef()
//
//   const handleNext = () => {
//     if (!isTransitioning) {
//       setCurrentIndex((prevIndex) => prevIndex + 1)
//       setIsTransitioning(true)
//     }
//   }
//
//   const handlePrev = () => {
//     if (!isTransitioning) {
//       setCurrentIndex((prevIndex) => prevIndex - 1)
//       setIsTransitioning(true)
//     }
//   }
//
//   const handlers = useSwipeable({
//     onSwipedLeft: handleNext,
//     onSwipedRight: handlePrev,
//     preventDefaultTouchmoveEvent: true,
//     trackMouse: true,
//   })
//
//   useEffect(() => {
//     if (isTransitioning) {
//       transitionRef.current = setTimeout(() => {
//         setIsTransitioning(false)
//         if (currentIndex === 0) {
//           setCurrentIndex(slides.length)
//         } else if (currentIndex === slides.length + 1) {
//           setCurrentIndex(1)
//         }
//       }, 500)
//     }
//     return () => clearTimeout(transitionRef.current)
//   }, [currentIndex, isTransitioning, slides.length])
//
//   const toggleFullscreen = (img = null) => {
//     setFullscreenImg(img)
//     setIsFullscreen((prev) => !prev)
//   }
//
//   const handleShow = () => setShowPopup(true)
//   const handleClose = () => setShowPopup(false)
//
//   return (
//     <div className={style.gallery}>
//       <div className="customContainer">
//         <div className="faq_title_block">
//           <p className="faq_title">Двері</p>
//           <div className="faq_title_line"></div>
//           <button onClick={handleShow} className={style.button_carousel_web}>
//             Отримати прорахунок
//           </button>
//         </div>
//
//         <div className={style.carouselWrap}>
//           <button
//             className={`${style.carouselArrow} ${style.left}`}
//             onClick={handlePrev}
//             disabled={isTransitioning}
//           >
//             <img src={gallery_arrow_left} alt="Previous" />
//           </button>
//           <button
//             className={`${style.carouselArrow} ${style.right}`}
//             onClick={handleNext}
//             disabled={isTransitioning}
//           >
//             <img src={gallery_arrow_right} alt="Next" />
//           </button>
//
//           <div className={style.carousel} {...handlers}>
//             <div
//               className={style.carouselContainer}
//               style={{
//                 transform: `translateX(-${currentIndex * 100}%)`,
//                 transition: isTransitioning
//                   ? 'transform 0.5s ease-in-out'
//                   : 'none',
//               }}
//             >
//               {totalSlides.map((slide, index) => (
//                 <div key={index} className={style.carouselSlide}>
//                   <img
//                     src={slide.slide1}
//                     alt="Slide 1"
//                     className={style.carouselSlide_1}
//                     onClick={() => toggleFullscreen(slide.slide1)}
//                   />
//                   {isLargeScreen && (
//                     <div className={style.carouselSlide_center}>
//                       <img
//                         src={slide.slide2_1}
//                         alt="Slide 2_1"
//                         className={style.carouselSlide_21}
//                         onClick={() => toggleFullscreen(slide.slide2_1)}
//                       />
//                       <img
//                         src={slide.slide2_2}
//                         alt="Slide 2_2"
//                         className={style.carouselSlide_22}
//                         onClick={() => toggleFullscreen(slide.slide2_2)}
//                       />
//                     </div>
//                   )}
//                   <img
//                     src={slide.slide3}
//                     alt="Slide 3"
//                     className={style.carouselSlide_3}
//                     onClick={() => toggleFullscreen(slide.slide3)}
//                   />
//                 </div>
//               ))}
//             </div>
//
//             <div className={style.carouselDots}>
//               {slides.slice(0, 8).map((_, index) => (
//                 <span
//                   key={index}
//                   className={`${style.carouselDot} ${
//                     index + 1 === currentIndex ? style.activeDot : ''
//                   }`}
//                   onClick={() => !isTransitioning && setCurrentIndex(index + 1)}
//                 ></span>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//
//       {isFullscreen && (
//         <div
//           className={style.fullscreenOverlay}
//           onClick={() => toggleFullscreen()}
//         >
//           <img
//             src={fullscreenImg}
//             className={style.fullscreenImg}
//             alt="Fullscreen view"
//           />
//         </div>
//       )}
//
//       <PopUpDoor show={showPopup} handleClose={handleClose} title="Popup Title">
//         <p>This is the content of the popup.</p>
//       </PopUpDoor>
//
//       <div className={style.button_carousel_block}>
//         <button onClick={handleShow} className={style.button_carousel_mobile}>
//           Отримати прорахунок
//         </button>
//       </div>
//     </div>
//   )
// }
//
// export { CarouselDoors }

import React, { useState, useEffect, useRef } from 'react'
import { useSwipeable } from 'react-swipeable'
import { useWindowSize } from '../../Components/Hooks/useWindowSize'
import style from '../Carousel/Carousel.module.css'
import {
  first_Slide1,
  first_Slide2_1,
  first_Slide3,
  second_Slide1,
  second_Slide2_1,
  second_Slide3,
  third_Slide1,
  third_Slide2_1,
  third_Slide3,
  gallery_arrow_left,
  gallery_arrow_right,
} from '../../Assets/Images/CarouselDoors'
import PopUpDoor from '../../Components/PopUpDoor/PopUpDoor'

const largeScreenSlides = [
  {
    slide1: first_Slide1,
    slide2_1: first_Slide2_1,
    slide3: first_Slide3,
  },
  {
    slide1: second_Slide1,
    slide2_1: second_Slide2_1,
    slide3: second_Slide3,
  },
  {
    slide1: third_Slide1,
    slide2_1: third_Slide2_1,
    slide3: third_Slide3,
  },
]

const smallScreenSlides = [
  { slide1: first_Slide1 },
  { slide1: first_Slide2_1 },
  { slide1: first_Slide3 },
  { slide1: second_Slide1 },
  { slide1: second_Slide2_1 },
  { slide1: second_Slide3 },
  { slide1: third_Slide1 },
  { slide1: third_Slide2_1 },
  { slide1: third_Slide3 },
]

const CarouselDoors = () => {
  const { width } = useWindowSize()
  const isLargeScreen = width >= 768
  const slides = isLargeScreen ? largeScreenSlides : smallScreenSlides
  const totalSlides = [slides[slides.length - 1], ...slides, slides[0]]

  const [currentIndex, setCurrentIndex] = useState(1)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [fullscreenImg, setFullscreenImg] = useState(null)
  const [showPopup, setShowPopup] = useState(false)

  const transitionRef = useRef()

  const handleNext = () => {
    if (!isTransitioning) {
      setCurrentIndex((prevIndex) => prevIndex + 1)
      setIsTransitioning(true)
    }
  }

  const handlePrev = () => {
    if (!isTransitioning) {
      setCurrentIndex((prevIndex) => prevIndex - 1)
      setIsTransitioning(true)
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  useEffect(() => {
    if (isTransitioning) {
      transitionRef.current = setTimeout(() => {
        setIsTransitioning(false)
        if (currentIndex === 0) {
          setCurrentIndex(slides.length)
        } else if (currentIndex === slides.length + 1) {
          setCurrentIndex(1)
        }
      }, 500)
    }
    return () => clearTimeout(transitionRef.current)
  }, [currentIndex, isTransitioning, slides.length])

  const toggleFullscreen = (img = null) => {
    setFullscreenImg(img)
    setIsFullscreen((prev) => !prev)
  }

  const handleShow = () => setShowPopup(true)
  const handleClose = () => setShowPopup(false)

  return (
    <div className={style.gallery}>
      <div className="customContainer">
        <div className="faq_title_block">
          <p className="faq_title">Двері</p>
          <div className="faq_title_line"></div>
          <button onClick={handleShow} className={style.button_carousel_web}>
            Отримати прорахунок
          </button>
        </div>

        <div className={style.carouselWrap}>
          <button
            className={`${style.carouselArrow} ${style.left}`}
            onClick={handlePrev}
            disabled={isTransitioning}
          >
            <img src={gallery_arrow_left} alt="Previous" />
          </button>
          <button
            className={`${style.carouselArrow} ${style.right}`}
            onClick={handleNext}
            disabled={isTransitioning}
          >
            <img src={gallery_arrow_right} alt="Next" />
          </button>

          <div className={style.carousel} {...handlers}>
            <div
              className={style.carouselContainer}
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
                transition: isTransitioning
                  ? 'transform 0.5s ease-in-out'
                  : 'none',
              }}
            >
              {totalSlides.map((slide, index) => (
                <div key={index} className={style.carouselSlide}>
                  <img
                    src={slide.slide1}
                    alt="Slide 1"
                    className={style.carouselSlide_1}
                    onClick={() => toggleFullscreen(slide.slide1)}
                  />

                  <div className={style.carouselSlide_center}>
                    <img
                      src={slide.slide2_1}
                      alt="Slide 2_1"
                      className={style.carouselSlide_21}
                      onClick={() => toggleFullscreen(slide.slide2_1)}
                    />
                    {/*<img*/}
                    {/*  src={slide.slide2_2}*/}
                    {/*  alt="Slide 2_2"*/}
                    {/*  className={style.carouselSlide_22}*/}
                    {/*  onClick={() => toggleFullscreen(slide.slide2_2)}*/}
                    {/*/>*/}
                  </div>

                  <img
                    src={slide.slide3}
                    alt="Slide 3"
                    className={style.carouselSlide_3}
                    onClick={() => toggleFullscreen(slide.slide3)}
                  />
                </div>
              ))}
            </div>

            <div className={style.carouselDots}>
              {slides.slice(0, 8).map((_, index) => (
                <span
                  key={index}
                  className={`${style.carouselDot} ${
                    index + 1 === currentIndex ? style.activeDot : ''
                  }`}
                  onClick={() => !isTransitioning && setCurrentIndex(index + 1)}
                ></span>
              ))}
            </div>
          </div>
        </div>
      </div>

      {isFullscreen && (
        <div
          className={style.fullscreenOverlay}
          onClick={() => toggleFullscreen()}
        >
          <img
            src={fullscreenImg}
            className={style.fullscreenImg}
            alt="Fullscreen view"
          />
        </div>
      )}

      <PopUpDoor show={showPopup} handleClose={handleClose} title="Popup Title">
        <p>This is the content of the popup.</p>
      </PopUpDoor>

      <div className={style.button_carousel_block}>
        <button onClick={handleShow} className={style.button_carousel_mobile}>
          Отримати прорахунок
        </button>
      </div>
    </div>
  )
}

export { CarouselDoors }
