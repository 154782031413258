import React from 'react'
import './workflow.css'

const Workflow = () => {
  return (
    <div className="workflow_block" id={'workflow'}>
      <div className="customContainer">
        <div className="faq_title_block">
          <div className="faq_title_line"></div>
          <div>
            <p className="faq_title">Процес співпраці</p>
          </div>
          <div className="faq_title_line"></div>
        </div>
        <div className="workflow_content">
          <div className="workflow_text_line">
            <div className="workflow_left_line">
              <p className="workflow_number">01/</p>
            </div>
            <div className="workflow_right_line">
              <p className="workflow_text">Заміри на обʼєкті</p>
            </div>
          </div>
          <div className="workflow_text_line">
            <div className="workflow_left_line">
              <p className="workflow_number">02/</p>
            </div>
            <div className="workflow_right_line">
              <p className="workflow_text">Підбір матеріалів</p>
            </div>
          </div>
          <div className="workflow_text_line">
            <div className="workflow_left_line">
              <p className="workflow_number">03/</p>
            </div>
            <div className="workflow_right_line">
              <p className="workflow_text">Укладення Договору</p>
            </div>
          </div>
          <div className="workflow_text_line">
            <div className="workflow_left_line">
              <p className="workflow_number">04/</p>
            </div>
            <div className="workflow_right_line">
              <p className="workflow_text">Виготовлення до 45 робочих днів</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Workflow
