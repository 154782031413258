import React from 'react'
import './faq.css'
import { Accordion } from 'react-bootstrap'

const Faq = () => {
  return (
    <div className="faq_block">
      <div className="customContainer">
        <div className="faq_title_block">
          <div className="faq_title_line"></div>
          <div>
            <p className="faq_title">Поширені запитання</p>
          </div>
          <div className="faq_title_line"></div>
        </div>
        <div className="customContainerFaq">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Де виготовляються меблі, сходи та двері ?
              </Accordion.Header>
              <Accordion.Body>
                Ми маємо власне виробництво у Львові, де команда професіоналів
                на сучасному устаткуванні вже більше 7 років виготовляє меблі та
                вироби з дерева для вас.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Чи є гарантія на меблі ?</Accordion.Header>
              <Accordion.Body>
                Ми підписуємо офіційний договір і надаємо гарантію 5 років на
                усі меблі. Також ви отримуєте гарантію на усю техніку і
                підсвітку, яку придбали у нас.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Як довго виготовляєте меблі?</Accordion.Header>
              <Accordion.Body>
                Термін виготовлення є індивідуальним, в залежності від об’єму та
                складності роботи. Згідно з Договором це до 45 робочих днів.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Скільки коштує доставка та монтаж?
              </Accordion.Header>
              <Accordion.Body>
                Доставка і монтаж проводиться безкоштовно. Ми маємо власний
                транспорт і команду монтажників.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>Ціна меблів?</Accordion.Header>
              <Accordion.Body>
                Ціна на меблі залежить від площі приміщення, використаного
                матеріалу, кількості механізмів і складності дизайнерського
                рішення.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>Які меблі ви виготовляєте?</Accordion.Header>
              <Accordion.Body>
                Ми займаємося повним і частковим меблюванням вашої оселі чи
                комерційного приміщення. В нас немає стандартних фабричних
                варіантів меблів: кожна наша робота – це реалізація
                індивідуального дизайнерського проєкту.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  )
}
export default Faq
