// Cases.js
import React, { useState } from 'react'
import styles from './Cases.module.css'
import {
  modern1,
  modern10,
  modern11,
  modern12,
  modern13,
  modern14,
  modern15,
  modern16,
  modern17,
  modern18,
  modern19,
  modern2,
  modern20,
  modern3,
  modern4,
  modern5,
  modern6,
  modern7,
  modern8,
  modern9,
} from '../../Assets/Images/Cases/Modern Cottage'
import {
  eco1,
  eco10,
  eco11,
  eco12,
  eco13,
  eco14,
  eco15,
  eco16,
  eco17,
  eco18,
  eco19,
  eco2,
  eco20,
  eco3,
  eco4,
  eco5,
  eco6,
  eco7,
  eco8,
  eco9,
} from '../../Assets/Images/Cases/Еко дім'
import {
  parus1,
  parus2,
  parus3,
  parus4,
  parus5,
  parus6,
  parus7,
  parus8,
} from '../../Assets/Images/Cases/ЖК Парус Смарт'
import {
  bilo1,
  bilo10,
  bilo11,
  bilo12,
  bilo2,
  bilo3,
  bilo4,
  bilo5,
  bilo6,
  bilo7,
  bilo8,
  bilo9,
} from '../../Assets/Images/Cases/Білогорща'
import {
  classic1,
  classic10,
  classic11,
  classic12,
  classic13,
  classic14,
  classic15,
  classic2,
  classic3,
  classic4,
  classic5,
  classic6,
  classic7,
  classic8,
  classic9,
} from '../../Assets/Images/Cases/Classic design'
import GalleryPopup from '../../Components/Gallery/Gallery'
import {
  wooden1,
  wooden10,
  wooden11,
  wooden2,
  wooden3,
  wooden4,
  wooden5,
  wooden6,
  wooden7,
  wooden8,
  wooden9,
} from '../../Assets/Images/Cases/Wooden magic'

const projects = [
  {
    title: 'Modern Cottage',
    location: 'с.Зубра Львівська обл',
    images: [
      modern9,
      modern1,
      modern2,
      modern3,
      modern4,
      modern5,
      modern6,
      modern7,
      modern8,
      modern10,
      modern11,
      modern12,
      modern13,
      modern14,
      modern15,
      modern16,
      modern17,
      modern18,
      modern19,
      modern20,
    ], // Можна додати більше фото для кожного проєкту
  },
  {
    title: 'ЖК Еко дім',
    location: 'Львів',
    images: [
      eco8,
      eco1,
      eco2,
      eco3,
      eco4,
      eco5,
      eco6,
      eco7,
      eco9,
      eco10,
      eco11,
      eco12,
      eco13,
      eco14,
      eco15,
      eco16,
      eco17,
      eco18,
      eco19,
      eco20,
    ],
  },
  {
    title: 'ЖК Парус смарт',
    location: 'Львів',
    images: [parus1, parus2, parus3, parus4, parus5, parus6, parus7, parus8],
  },
  {
    title: 'Білогорща',
    location: 'Львівська область',
    images: [
      bilo1,
      bilo2,
      bilo3,
      bilo4,
      bilo5,
      bilo6,
      bilo7,
      bilo8,
      bilo9,
      bilo10,
      bilo11,
      bilo12,
    ],
  },
  {
    title: 'Classic design',
    location: 'Львів, ЖК Софіївка',
    images: [
      classic1,
      classic2,
      classic3,
      classic4,
      classic5,
      classic6,
      classic7,
      classic8,
      classic9,
      classic10,
      classic11,
      classic12,
      classic13,
      classic14,
      classic15,
    ],
  },
  {
    title: 'ЖК Парус смарт',
    location: 'Львів',
    images: [
      wooden9,
      wooden1,
      wooden2,
      wooden3,
      wooden4,
      wooden5,
      wooden6,
      wooden7,
      wooden8,
      wooden10,
      wooden11,
    ],
  },
]

const Cases = () => {
  const [selectedProject, setSelectedProject] = useState(null)

  const openGallery = (project) => {
    setSelectedProject(project)
  }

  const closeGallery = () => {
    setSelectedProject(null)
  }

  return (
    <section className={styles.container} id={'cases'}>
      <div className={`${styles.wrap} customContainer`}>
        <div className={styles.title_block}>
          <div className={styles.line}></div>
          <h2 className={styles.title}>Реалізовані проекти</h2>
          <div className={styles.line}></div>
        </div>
        <div className={styles.grid}>
          {projects.map((project, index) => (
            <div
              key={index}
              className={styles.card}
              style={{ backgroundImage: `url(${project.images[0]})` }}
            >
              <div className={styles.overlay}></div>
              <div className={styles.desc}>
                <div className={styles.descwrap}>
                  <h3 className={styles.projectTitle}>{project.title}</h3>
                  <p className={styles.location}>{project.location}</p>
                </div>
                <button
                  className={styles.button}
                  onClick={() => openGallery(project)}
                >
                  Детальніше
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Render GalleryPopup if a project is selected */}
      {selectedProject && (
        <GalleryPopup images={selectedProject.images} onClose={closeGallery} />
      )}
    </section>
  )
}

export default Cases
