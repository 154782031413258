import React, { useState, useRef, useEffect } from 'react'
import styles from './Gallery.module.css'

const GalleryPopup = ({ images, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const thumbnailListRef = useRef(null)
  const thumbnailRef = useRef(null)
  const [thumbnailHeight, setThumbnailHeight] = useState(0)
  const touchStartX = useRef(0)
  const touchStartY = useRef(0)
  const mouseStartX = useRef(0)
  const mouseStartY = useRef(0)
  const isDragging = useRef(false)
  const [thumbnailWidth, setThumbnailWidth] = useState(0)


  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    )
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1,
    )
  }

  useEffect(() => {
    if (thumbnailRef.current) {
      setThumbnailHeight(thumbnailRef.current.clientHeight +20)
    }
  }, [])
  useEffect(() => {
    if (thumbnailRef.current) {
      setThumbnailWidth(thumbnailRef.current.clientWidth +20)
    }
  }, [])
  useEffect(() => {
    if (thumbnailListRef.current) {
      const scrollPosition = currentIndex * thumbnailHeight - thumbnailHeight
      thumbnailListRef.current.scrollTo({
        top: scrollPosition,
      })
    }
  }, [currentIndex, thumbnailHeight])
  useEffect(() => {
    if (thumbnailListRef.current) {
      const isMobile = window.innerWidth < 1200;

      if (isMobile) {
        // Logic for mobile devices (less than 1200px)
        const scrollPosition = currentIndex * thumbnailWidth - thumbnailWidth; // Use thumbnailWidth for horizontal scrolling
        console.log('Mobile: Current Index:', currentIndex, 'Scroll Position:', scrollPosition);

        thumbnailListRef.current.scrollTo({
          left: scrollPosition,
        });
      } else {
        // Logic for desktop devices (1200px or more)
        const scrollPosition = currentIndex * thumbnailHeight - thumbnailHeight; // Adjusting for height-based scrolling
        console.log('Desktop: Current Index:', currentIndex, 'Scroll Position:', scrollPosition);

        thumbnailListRef.current.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [currentIndex, thumbnailHeight, thumbnailWidth]);


  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX
    touchStartY.current = e.touches[0].clientY
  }

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX
    const touchEndY = e.changedTouches[0].clientY
    const touchDeltaX = touchStartX.current - touchEndX
    const touchDeltaY = touchStartY.current - touchEndY

    if (Math.abs(touchDeltaX) > Math.abs(touchDeltaY)) {
      // Horizontal swipe
      if (touchDeltaX > 50) {
        handleNext() // Swipe left
      } else if (touchDeltaX < -50) {
        handlePrev() // Swipe right
      }
    } else {
      // Vertical swipe
      if (touchDeltaY > 50) {
        handleNext() // Swipe up
      } else if (touchDeltaY < -50) {
        handlePrev() // Swipe down
      }
    }
  }


  const handleMouseMove = (e) => {
    if (!isDragging.current) return

    const mouseEndX = e.clientX
    const mouseEndY = e.clientY
    const mouseDeltaX = mouseStartX.current - mouseEndX
    const mouseDeltaY = mouseStartY.current - mouseEndY

    if (Math.abs(mouseDeltaX) > Math.abs(mouseDeltaY)) {
      // Horizontal drag
      if (mouseDeltaX > 50) {
        handleNext() // Drag left
        isDragging.current = false // Prevent continuous dragging
      } else if (mouseDeltaX < -50) {
        handlePrev() // Drag right
        isDragging.current = false // Prevent continuous dragging
      }
    } else {
      // Vertical drag
      if (mouseDeltaY > 50) {
        handleNext() // Drag up
        isDragging.current = false // Prevent continuous dragging
      } else if (mouseDeltaY < -50) {
        handlePrev() // Drag down
        isDragging.current = false // Prevent continuous dragging
      }
    }
  }
  const handleMouseDown = (e) => {
    mouseStartX.current = e.clientX
    mouseStartY.current = e.clientY
    isDragging.current = true
  }

  const handleMouseUp = () => {
    isDragging.current = false
  }

  return (
    <div className={styles.popupOverlay} onClick={onClose}>
      <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.6801 1.86444C23.7815 1.76302 23.862 1.64262 23.9169 1.51011C23.9718 1.37761 24 1.23559 24 1.09216C24 0.948737 23.9718 0.806717 23.9169 0.67421C23.862 0.541702 23.7815 0.421303 23.6801 0.319887C23.5787 0.21847 23.4583 0.138022 23.3258 0.0831359C23.1933 0.0282497 23.0513 0 22.9078 0C22.7644 0 22.6224 0.0282497 22.4899 0.0831359C22.3574 0.138022 22.237 0.21847 22.1356 0.319887L12 10.4576L1.86444 0.319887C1.76302 0.21847 1.64262 0.138022 1.51011 0.0831359C1.37761 0.0282497 1.23559 1.0686e-09 1.09216 0C0.948737 -1.0686e-09 0.806717 0.0282497 0.67421 0.0831359C0.541702 0.138022 0.421303 0.21847 0.319887 0.319887C0.21847 0.421303 0.138022 0.541702 0.0831359 0.67421C0.0282497 0.806717 -1.0686e-09 0.948737 0 1.09216C1.0686e-09 1.23559 0.0282497 1.37761 0.0831359 1.51011C0.138022 1.64262 0.21847 1.76302 0.319887 1.86444L10.4576 12L0.319887 22.1356C0.115066 22.3404 0 22.6182 0 22.9078C0 23.1975 0.115066 23.4753 0.319887 23.6801C0.524707 23.8849 0.802502 24 1.09216 24C1.38182 24 1.65962 23.8849 1.86444 23.6801L12 13.5424L22.1356 23.6801C22.3404 23.8849 22.6182 24 22.9078 24C23.1975 24 23.4753 23.8849 23.6801 23.6801C23.8849 23.4753 24 23.1975 24 22.9078C24 22.6182 23.8849 22.3404 23.6801 22.1356L13.5424 12L23.6801 1.86444Z"
              fill="white"
            />
          </svg>
        </button>
        <div className={styles.galleryContainer}>
          <div
            className={styles.mainImageContainer}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            style={{ touchAction: 'none' }} // Disable default touch actions
          >
            <img
              src={images[currentIndex]}
              alt={`${currentIndex + 1}`}
              className={styles.mainImage}
              draggable="false"
              onDragStart={(e) => e.preventDefault()}
            />
          </div>
          <div className={styles.thumbnailContainer}>
            <button className={styles.arrow} onClick={handlePrev}>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="22" viewBox="0 0 40 22" fill="none">
                <path d="M1.73141 21.1587C2.27025 21.6974 3.00096 22 3.76287 22C4.52478 22 5.2555 21.6974 5.79433 21.1587L20.0174 6.93565L34.2405 21.1587C34.7824 21.6821 35.5082 21.9717 36.2616 21.9652C37.015 21.9586 37.7356 21.6565 38.2684 21.1237C38.8011 20.591 39.1033 19.8703 39.1099 19.1169C39.1164 18.3635 38.8268 17.6377 38.3034 17.0958L22.0489 0.841277C21.51 0.302608 20.7793 0 20.0174 0C19.2555 0 18.5248 0.302608 17.9859 0.841277L1.73141 17.0958C1.19274 17.6346 0.890137 18.3654 0.890137 19.1273C0.890137 19.8892 1.19274 20.6199 1.73141 21.1587Z" fill="white"/>
              </svg>
            </button>
            <div className={styles.thumbnailList} ref={thumbnailListRef}>
              {images.map((img, index) => {
                const isActive = index === currentIndex
                return (
                  <img
                    key={index}
                    src={img}
                    alt={`Thumbnail ${index + 1}`}
                    ref={index === 0 ? thumbnailRef : null}
                    className={`${styles.thumbnail} ${
                      isActive ? styles.active : ''
                    }`}
                    onClick={() => setCurrentIndex(index)}
                    draggable="false"
                    onDragStart={(e) => e.preventDefault()}
                  />
                )
              })}
            </div>
            <button className={styles.arrow} onClick={handleNext}>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="22" viewBox="0 0 40 22" fill="none">
                <path d="M1.73141 0.841278C2.27025 0.302608 3.00096 0 3.76287 0C4.52478 0 5.2555 0.302608 5.79433 0.841278L20.0174 15.0644L34.2405 0.841278C34.7824 0.317875 35.5082 0.0282574 36.2616 0.0348034C37.015 0.0413513 37.7356 0.343536 38.2684 0.87628C38.8011 1.40902 39.1033 2.12969 39.1099 2.88308C39.1164 3.63646 38.8268 4.36227 38.3034 4.90419L22.0489 21.1587C21.51 21.6974 20.7793 22 20.0174 22C19.2555 22 18.5248 21.6974 17.9859 21.1587L1.73141 4.90419C1.19274 4.36536 0.890137 3.63464 0.890137 2.87273C0.890137 2.11082 1.19274 1.38011 1.73141 0.841278Z" fill="white"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GalleryPopup
