import React, { useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { PhoneInput } from 'react-international-phone'
import { useForm } from 'react-hook-form'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { toast } from 'react-toastify'
import { Toastify } from '../../Components/Toastify/Toastify'
import { sendInTg } from '../../Constants'
import style from './PopupForm.module.css'
import './modal.css'
import { CloseBtn } from '../../Assets/Icons/icons'
import './carousel_select.css'
import { useNavigate } from 'react-router-dom' // Import useNavigate

const PopUpFurniture = ({ show, handleClose, title }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'all' })

  const [phone, setPhone] = useState('')
  const [isBlurredPhone, setIsBlurredPhone] = useState(false)
  const navigate = useNavigate()

  const isPhoneValid = (phone) => {
    const phoneUtil = PhoneNumberUtil.getInstance()
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
    } catch (error) {
      return false
    }
  }

  const isValid = isPhoneValid(phone)
  const toastId = useRef(null)

  const onSubmit = async (data) => {
    if (isValid) {
      if (toastId.current) {
        toast.dismiss(toastId.current)
      }
      toastId.current = toast(<Toastify type={'pending'} />, {
        autoClose: false,
        hideProgressBar: true,
      })

      let msg = `🏡Прорахунок Кухня на замовлення:\nТип кухні - ${data.kitchenType}\nРозмір кухні - ${data.kitchenSize}\nМатеріал фасаду - ${data.frontMaterial}\nМатеріал стільниці - ${data.countertopMaterial}\nНомер телефону - ${phone}`

      let resp = await sendInTg(msg)
      if (resp.ok) {
        toast.update(toastId.current, {
          render: <Toastify type={'success'} />,
          type: 'success',
          icon: false,
          autoClose: 3000,
          hideProgressBar: false,
        })
        navigate('/thankyou')
      } else {
        toast.update(toastId.current, {
          render: 'Упс, щось пішло не так...',
          type: 'error',
          autoClose: 7000,
        })
      }

      reset()
      setPhone('')
      setIsBlurredPhone(false)
    } else {
      setIsBlurredPhone(true)
    }
  }

  const redBord = { boxShadow: 'inset 0px 0px 5px 5px rgba(255,0,0,.2)' }
  const greenBord = { boxShadow: 'inset 0px 0px 5px 5px rgba(100,255,100,.2)' }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className={style.popup_block}>
        <Modal.Body>
          <button className={style.popup_close_btn} onClick={handleClose}>
            <img src={CloseBtn} alt="" />
          </button>
          <div className={style.wrap}>
            <div>
              <div className={style.box}>
                <div className={style.box_texts}>
                  <h2 className={style.box_texts__title}>Прорахунок</h2>
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={style.box_form}
                >
                  {/* Kitchen Type Selection */}
                  <div className={style.box_form__box}>
                    <label
                      htmlFor="kitchenType"
                      className={style.form_box_label}
                    >
                      Який тип кухні Вам потрібен?
                    </label>
                    <div
                      className={style.box_form__box_wrap}
                      style={
                        errors.kitchenType && touchedFields.kitchenType
                          ? redBord
                          : touchedFields.kitchenType && !errors.kitchenType
                          ? greenBord
                          : null
                      }
                    >
                      <select
                        id="kitchenType"
                        className={style.box_form__box_wrap__inp}
                        {...register('kitchenType', { required: true })}
                      >
                        <option value="">Оберіть тип кухні</option>
                        <option value="Кутова кухня">Кутова кухня</option>
                        <option value="Паралельна кухня">
                          Паралельна кухня
                        </option>
                        <option value="Пряма кухня">Пряма кухня</option>
                        <option value="П-подібна кухня">П-подібна кухня</option>
                        <option value="Острівна кухня">Острівна кухня</option>
                        <option value="G-подібна кухня">G-подібна кухня</option>
                      </select>
                    </div>
                  </div>

                  {/* Kitchen Size Selection */}
                  <div className={style.box_form__box}>
                    <label
                      htmlFor="kitchenSize"
                      className={style.form_box_label}
                    >
                      Оберіть розмір кухні
                    </label>
                    <div
                      className={style.box_form__box_wrap}
                      style={
                        errors.kitchenSize && touchedFields.kitchenSize
                          ? redBord
                          : touchedFields.kitchenSize && !errors.kitchenSize
                          ? greenBord
                          : null
                      }
                    >
                      <select
                        id="kitchenSize"
                        className={style.box_form__box_wrap__inp}
                        {...register('kitchenSize', { required: true })}
                      >
                        <option value="">Оберіть розмір кухні</option>
                        <option value="До 3 м">До 3 м</option>
                        <option value="3-5 м">3-5 м</option>
                        <option value="5-7 м">5-7 м</option>
                        <option value="Більше 7 м">Більше 7 м</option>
                      </select>
                    </div>
                  </div>

                  {/* Front Material Selection */}
                  <div className={style.box_form__box}>
                    <label
                      htmlFor="frontMaterial"
                      className={style.form_box_label}
                    >
                      Вкажіть матеріал фасаду
                    </label>
                    <div
                      className={style.box_form__box_wrap}
                      style={
                        errors.frontMaterial && touchedFields.frontMaterial
                          ? redBord
                          : touchedFields.frontMaterial && !errors.frontMaterial
                          ? greenBord
                          : null
                      }
                    >
                      <select
                        id="frontMaterial"
                        className={style.box_form__box_wrap__inp}
                        {...register('frontMaterial', { required: true })}
                      >
                        <option value="">Оберіть матеріал фасаду</option>
                        <option value="ЛДСП">ЛДСП</option>
                        <option value="МДФ фарбований">МДФ фарбований</option>
                        <option value="МДФ шпонований">МДФ шпонований</option>
                        <option value="Акрилові фасади">Акрилові фасади</option>
                        <option value="Fenix">Fenix</option>
                      </select>
                    </div>
                  </div>

                  {/* Countertop Material Selection */}
                  <div className={style.box_form__box}>
                    <label
                      htmlFor="countertopMaterial"
                      className={style.form_box_label}
                    >
                      Оберіть матеріал стільниці
                    </label>
                    <div
                      className={style.box_form__box_wrap}
                      style={
                        errors.countertopMaterial &&
                        touchedFields.countertopMaterial
                          ? redBord
                          : touchedFields.countertopMaterial &&
                            !errors.countertopMaterial
                          ? greenBord
                          : null
                      }
                    >
                      <select
                        id="countertopMaterial"
                        className={style.box_form__box_wrap__inp}
                        {...register('countertopMaterial', { required: true })}
                      >
                        <option value="">Оберіть матеріал стільниці</option>
                        <option value="ДСП">ДСП</option>
                        <option value="Штучний камінь">Штучний камінь</option>
                        <option value="Керамограніт">Керамограніт</option>
                        <option value="HPL">HPL</option>
                      </select>
                    </div>
                  </div>

                  {/* Phone Number Input */}
                  <div className={style.box_form__box}>
                    <label htmlFor="phone" className={style.form_box_label}>
                      Номер телефону
                    </label>
                    <div
                      className={style.box_form__box_wrap}
                      style={
                        !isValid && isBlurredPhone
                          ? redBord
                          : isValid && isBlurredPhone
                          ? greenBord
                          : null
                      }
                    >
                      <PhoneInput
                        defaultCountry="ua"
                        className={style.box_form__box_wrap__telInp}
                        onChange={(e) => setPhone(e)}
                        value={phone}
                        onFocus={() => setIsBlurredPhone(true)}
                      />
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className={style.box_form__btn_box}>
                    <button type="submit" className={style.box_form__btn}>
                      Надіслати
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  )
}

export default PopUpFurniture
