import React from 'react'
import './footer.css'
import { HeaderLogo } from '../../Assets/Images/Header'
import {
  EmailIcon,
  FacebookIcon,
  Instagram,
  LocationIcon,
  TelefonIcon,
  Telegram,
  Viber,
} from '../../Assets/Icons/Footer'
import { Link } from 'react-scroll'

const Footer = () => {
  return (
    <div className="footer_block">
      <div className="customContainer footer_content">
        <div className="footer_logo_block">
          <Link to={'start'} className="footer_logo">
            <img className="footer_logo" src={HeaderLogo} alt="HeaderLogo" />
          </Link>
        </div>
        <div className="footer_nav_block">
          <Link to={'carousel'}>
            <p className="footer_nav_link">Послуги</p>
          </Link>
          <Link to={'cases'}>
            <p className="footer_nav_link">Реалізовані проекти</p>
          </Link>
          <Link to={'workflow'}>
            <p className="footer_nav_link">Співпраця</p>
          </Link>
        </div>

        <div className="footer_contact_blocks">
          <div className="footer_contact_left">
            <p className="footer_contact_title">Контакти</p>

            <a
              className="footer_contact_a"
              href="tel:+380960718371"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="footer_contact_block">
                <img src={TelefonIcon} alt="TelefonIcon" />
                <p className="footer_contact_link">+380-(96)-071-83-71</p>
              </div>
            </a>
            <a
              className="footer_contact_a"
              href="tel:+380997831571 "
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="footer_contact_block">
                <img src={TelefonIcon} alt="TelefonIcon" />
                <p className="footer_contact_link">+380-(99)-783-15-71</p>
              </div>
            </a>
            <a
              className="footer_contact_a"
              href="tel:+380635497715"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="footer_contact_block">
                <img src={TelefonIcon} alt="TelefonIcon" />
                <p className="footer_contact_link">+380-(63)-549-77-15</p>
              </div>
            </a>
          </div>

          <div className="footer_contact_right">
            <div className="footer_soc_block">
              <a
                className="footer_contact_a"
                href="https://www.facebook.com/korpusnimeblilviv"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="footer_contact_block">
                  <img src={FacebookIcon} alt="FacebookIcon" />
                  {/*<p className="footer_contact_link">@Корпорація Меблів</p>*/}
                </div>
              </a>
              <a
                className="footer_contact_a"
                href="https://www.instagram.com/korporacia_mebliv?igsh=OWcwN3E5ZjlqYjNz"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="footer_contact_block">
                  <img src={Instagram} alt="Instagram" />
                  {/*<p className="footer_contact_link">@korporacia_mebliv</p>*/}
                </div>
              </a>
              <a
                className="footer_contact_a"
                href="viber://chat?number=+380635497715"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="footer_contact_block">
                  <img src={Viber} alt="Viber" />
                  {/*<p className="footer_contact_link">+380-(63)-549-77-15</p>*/}
                </div>
              </a>
              <a
                className="footer_contact_a"
                href="https://t.me/+380635497715 "
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="footer_contact_block">
                  <img src={Telegram} alt="Telegram" />
                  {/*<p className="footer_contact_link">+380-(63)-549-77-15</p>*/}
                </div>
              </a>
            </div>
            <a
              className="footer_contact_a"
              href="https://maps.app.goo.gl/f1F9CCBGbM3ZmuPo7"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="footer_contact_block">
                <img src={LocationIcon} alt="LocationIcon" />
                <p className="footer_contact_link">
                  м. Львів вул. Промислова 45
                </p>
              </div>
            </a>
            <a
              className="footer_contact_a"
              href="mailto:Korporacia.mebliv@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="footer_contact_block">
                <img src={EmailIcon} alt="EmailIcon" />
                <p className="footer_contact_link">
                  Korporacia.mebliv@gmail.com
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
