import React from 'react'
import MainBlock from '../../Modules/MainBlock/MainBlock'
import Numbers from '../../Modules/Numbers/Numbers'
import About from '../../Modules/About/About'
import { Carousel } from '../../Modules/Carousel/Carousel'
import { CarouselStairs } from '../../Modules/CarouselStairs/CarouselStairs'
import { CarouselDoors } from '../../Modules/CarouselDoors/CarouselDoors'
import { ConnectForm } from '../../Modules/ConnectForm'
import WhuUs from '../../Modules/WhyUs/WhuUs'
import Clients from '../../Modules/Clients/Clients'
import Cases from '../../Modules/Cases/Cases'
import Workflow from '../../Modules/Workflow/Workflow'
import Faq from '../../Modules/Faq/Faq'
import { GalleryVideo } from '../../Modules/GalleryVideo/GalleryVideo'

const MainPage = () => {
  return (
    <div>
      <MainBlock />
      <Numbers />
      <About />
      <Carousel />
      <CarouselStairs />
      <CarouselDoors />
      <ConnectForm />
      <WhuUs />
      <Clients />
      <Cases />
      <Workflow />
      <ConnectForm />
      <Faq />
      <GalleryVideo />
    </div>
  )
}

export default MainPage
