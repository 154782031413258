import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './MainBlock.module.css'

const MainThank = () => {
  const navigate = useNavigate()

  const handleGoHome = () => {
    navigate('/')
  }

  return (
    <div className={styles.mainBlock}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          <span className={styles.title_mod}></span> Дякуємо за вашу заявку!
        </h1>
        <br />
        <button className={styles.button} onClick={handleGoHome}>
          Повернутись на головну сторінку
        </button>
      </div>
    </div>
  )
}

export default MainThank
