import React from 'react'
import './whyus.css'

const WhuUs = () => {
  return (
    <div className="partnersdev_block">
      <div className="customContainer ">
        <div className="partnersdev_title_block">
          <div>
            <p className="partnersdev_title">Чому обирають нас?</p>
          </div>
          <div className="partnersdev_title_line"></div>
        </div>
        <div className="partnersdev_content">
          <div className="partnersdev_content_left">
            <div className="whyus_text_block">
              <p className="whyus_text_title">Професійна команда</p>
              <p className="whyus_text">
                Ми - справжні фанати своєї справи, які втілять вашу мрію в
                реальність{' '}
              </p>
            </div>
            <div className="whyus_text_block">
              <p className="whyus_text_title">Гарантія</p>
              <p className="whyus_text">
                Гарантія на монтаж – 3 роки, на фурнітуру - 5 років{' '}
              </p>
            </div>
            <div className="whyus_text_block">
              <p className="whyus_text_title">Преміальна якість</p>
              <p className="whyus_text">
                Ми використовуємо лише високоякісні та вологостійкі матеріали,
                що гарантують довговічність та надійність{' '}
              </p>
            </div>
            <div className="whyus_text_block">
              <p className="whyus_text_title">Клієнтоорієнтовність</p>
              <p className="whyus_text">
                Ви озвучуєте та показуєте усі побажання, обираєте дизайн,
                матеріали та конструкцію{' '}
              </p>
            </div>
            <div className="whyus_text_block">
              <p className="whyus_text_title">Індивідуальність</p>
              <p className="whyus_text">
                Наша команда створює меблі, які не зустрінеш на прилавках
                магазинів{' '}
              </p>
            </div>
          </div>
          <div className="partnersdev_content_right"></div>
        </div>
      </div>
    </div>
  )
}

export default WhuUs
