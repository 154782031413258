const baseURL = 'https://znayeshapi.pythonanywhere.com/api/v1.0/send_bot'
const validator =
  'NzYxNTA3NzY4NzpBQUU2bm0yYkNCMDRpdTBiU1FZOXplZFE4RjJxWTVsOUg5WQ=='
const chatId = '-1002167692873'

export { baseURL, chatId, validator }

const mapLink =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2599.092706701277!2d23.534795576492893!3d49.3503938714031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473a4eead55df9f5%3A0x422363e6b1e87e0f!2z0LLRg9C7LiDQoNCw0L3QtdCy0LjRhtGM0LrQsCwgMTQsINCU0YDQvtCz0L7QsdC40YcsINCb0YzQstGW0LLRgdGM0LrQsCDQvtCx0LvQsNGB0YLRjCwgODIxMDA!5e0!3m2!1suk!2sua!4v1725126074942!5m2!1suk!2sua'

export { mapLink }
