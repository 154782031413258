import React from 'react'
import './about.css'

const About = () => {
  return (
    <div className="aboutrepairs_block">
      <div className="customContainer ">
        <div className="faq_title_block">
          <div className="faq_title_line"></div>
          <div>
            <p className="faq_title">Про нас</p>
          </div>
          <div className="faq_title_line"></div>
        </div>

        <div className="aboutr_content">
          <div className="aboutr_content_left">
            <p className="aboutr_content_text">
              Корпорація меблів — це сімейна компанія з понад 7-річним досвідом
              на ринку, що спеціалізується на виготовленні корпусних меблів,
              меблів з натуральної деревини, сходів та дверей. <br /> <br /> Ми
              цінуємо якість і затишок, забезпечуючи преміальні вироби для
              вашого дому або бізнесу в короткі терміни. <br />
              <br /> Працюємо як з приватними клієнтами, так і з комерційними
              проєктами.
            </p>
          </div>
          <div className="aboutr_content_right"></div>
        </div>
      </div>
    </div>
  )
}

export default About
